<template>
  <section>
    <v-divider v-if="dividerPosition === 'top'" class="my-8"></v-divider>
    <v-row justify="space-between">
      <v-col :cols="numOfCols">
        <h3 class="purple--text">{{ title }}</h3>
      </v-col>
      <v-col v-if="showDownloadBtn">
        <v-btn
          :loading=loadingDownload
          :title="downloadTitle"
          icon
          tile
          color="primary"
          @click="emitDownload"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider v-if="dividerPosition === 'bottom'" class="my-8"></v-divider>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    dividerPosition: {
      type: String,
      default: 'top'
    },
    showDownloadBtn: {
      type: Boolean,
      default: false
    },
    loadingDownload: {
      type: Boolean,
      default: false
    },
    downloadTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      //
    }
  },
  computed: {
    numOfCols() {
      return this.showDownloadBtn ? '11' : '12'
    }
  },
  methods: {
    emitDownload() {
      this.$emit('downloadAction')
    }
  }
}
</script>

<style>

</style>