<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-form-note-of-mandatory />
          <kn-select
            class="mt-4"
            label="Producto:"
            v-model="productId"
            :rules="[]"
            :items="products"
            item-value="id"
            item-text="descripcion_corta"
            :disabled="isEditMode"
            @change="findProductPrice"
          />
          <kn-select
            class="mt-4"
            label="Opción de producto:"
            v-model="inventory.id_opcion_producto"
            :rules="[]"
            :items="options"
            item-value="id"
            item-text="nombre_opcion"
            :disabled="isEditMode"
          />
          <kn-select
            label="Almacén:"
            v-model="inventory.id_almacen"
            :rules="[]"
            :items="warehouseArray"
            item-value="id"
            item-text="nombre_almacen"
            :disabled="isEditMode"
          />
          <kn-text-field
            class="mt-4"
            label="Cantidad:"
            v-model.number="inventory.cantidad_disponible"
            :rules="[]"
            :disabled="isEditMode"
          />
          <kn-text-field
            class="mt-4"
            label="Precio unitario:"
            v-model.number="distributionPrice"
            :rules="[]"
            disabled
          />
          <kn-select
            label="Moneda:"
            v-model="inventory.id_moneda"
            :rules="[]"
            :items="coins"
            item-value="id"
            item-text="nombre_moneda"
            disabled
          />
          <kn-select
            label="Unidad de medida:"
            v-model="inventory.id_unidad_medida"
            :rules="[]"
            :items="measurementUnits"
            item-value="id"
            item-text="dato"
            :disabled="isEditMode"
          />
          <kn-text-area 
            label="Comentarios"
            v-model="inventory.comentarios"
            :rules="[]"
            :disabled="isEditMode"
          />
          <template  v-if="isEditMode">
            <kn-form-subtitle
              title="Actualizar inventario"
            />
            <kn-text-field
              class="mt-4"
              label="Cantidad a agregar:"
              v-model.number="amountToAdd"
              :rules="[]"
            />
          </template>
          <kn-form-action-buttons
            @accept="save"
            @cancel="cancel"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnTextArea from '@/components/inputs/KnTextArea.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
// import KnTabs from '@/components/KnTabs.vue'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnFormTitle from '../../../components/KnFormTitle.vue'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
export default {
  components: {
    KnFormSubtitle,
    KnTextField,
    KnTextArea,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnBackToTopButton,
    KnFormTitle,
    KnFormNoteOfMandatory,
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Información básica', value: 100 },       
      ],
      valueDeterminate: 100,
      routerName: 'Inventario',
      productId: null,
      distributionPrice: null,
      inventoryId: null,
      inventory: {
        id_opcion_producto: null,
        id_almacen: null,
        cantidad_disponible: null,
        id_unidad_medida: null,
        comentarios: null,
        fecha_ultima_actualizacion: new Date(),
        id_autor: null,
        id_institucion_educativa: null
      },
      products: [],
      options: [],
      warehouseArray: [],
      coins: [],
      measurementUnits: [],
      movementTypes: [],
      movementReasons: [],
      amountToAdd: null,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse']),
    title() {
      return this.entity === null ? 'Agregar inventario' : 'Actualizar inventario'
    },
    isEditMode() {
      return this.entity !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Producto actualizado con exito!' : 'Producto registrado con exito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    amountToAddValid() {
      return this.amountToAdd !== null && this.amountToAdd > 0
    }
  },
  async created() {
    this.loading = true
    this.alertText = 'Por favor, espere. Cargando...'
    this.showAlert = true
    this.alertType = 'info'

    this.products = await this.fetchResultsByEI('productos', 'producto', this.institutionId)
    this.measurementUnits = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
    this.options = await this.fetchResultsByEI('productos', 'opciones-producto', this.institutionId)
    this.warehouseArray = await this.fetchResultsByEI('inventarios', 'almacen', this.institutionId)
    
    if (this.isEditMode) {
      // console.log('Se deben llenar los campos');
      this.fillData()
    } else {
      this.inventory.id_institucion_educativa = this.institutionId
      this.inventory.id_autor = this.userData.id
      this.inventory.id_almacen = this.warehouse.id
    }
    this.showAlert = false
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async createInventory() {
      try {
        const response = await this.postObj('/inventarios/crear-inventario', {
          id_producto: this.productId,
          id_opcion_producto: this.inventory.id_opcion_producto,
          id_almacen: this.inventory.id_almacen,
          cantidad_disponible: this.inventory.cantidad_disponible,
          id_unidad_medida: this.inventory.id_unidad_medida,
          comentarios: this.inventory.comentarios,
          fecha_ultima_actualizacion: this.inventory.fecha_ultima_actualizacion,
          id_autor: this.inventory.id_autor,
          id_institucion_educativa: this.inventory.id_institucion_educativa
        })
        if (response) {
          this.alertText = 'Inventario creado con exito'
        } else {
          this.warnings.push('No se pudo crear inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async updateInventory() {
      try {
        const response = await this.postObj('/inventarios/update-inventario', {
          id: this.inventory.id,
          id_producto: this.productId,
          id_opcion_producto: this.inventory.id_opcion_producto,
          id_almacen: this.inventory.id_almacen,
          cantidad_disponible: this.inventory.cantidad_disponible + this.amountToAdd,
          id_unidad_de_medida: this.inventory.id_unidad_medida,
          comentarios: this.inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          id_autor: this.inventory.id_autor,
          id_institucion_educativa: this.inventory.id_institucion_educativa
        })
        if (response) {
          this.alertText = 'Inventario actualizado con exito'
        } else {
          this.errors.push('No se pudo actualizar inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async createInventoryMovement() {
      try {
        this.movementTypes = await this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
        this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
        const movementType = this.movementTypes.find(mType => mType.dato === 'Entrada')
        const movementReason = this.movementReasons.find(mReason => mReason.dato === 'Compra')

        const initialAmount = this.inventory.cantidad_disponible
        const amountOfMovement = this.isEditMode ? this.amountToAdd : this.inventory.cantidad_disponible
        const finalAmount = initialAmount + amountOfMovement
        const unitPrice = this.distributionPrice
        const valueOfMovement = unitPrice * amountOfMovement

        const movementObj = {
          id_producto: this.productId,
          id_tipo_movimiento: movementType.id, // Tipo de movimiento: entrada
          id_motivo_movimiento: movementReason.id, // Motivo de movimiento: compra
          id_almacen: this.inventory.id_almacen,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: this.inventory.id_moneda,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }

        const response = await this.postObj('/inventarios/crear-movimiento-inventario', movementObj)

        if (response) {
          this.alertText = 'Movimiento de inventario creado con exito'
        } else {
          this.errors.push('No se pudo crear movimiento de inventario.')
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    },
    async createObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        this.inventory.id_producto = this.productId
        if (!this.objectHasNulls(this.inventory)) {
          await this.createInventory()
          await this.createInventoryMovement()
        }

        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
        this.loading = false
      } catch (error) {
        console.error('Error al intentar crear inventario o movimiento inventario');
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        this.inventory.id_producto = this.productId
        if (this.amountToAddValid) {
          // console.log('Tiene inventario y se a introducido una cantidad a agregar');
          await this.updateInventory()
          await this.createInventoryMovement()
        }

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al intentar actualizar inventario o crear movimiento inventario')
      }
    },
    async save() {
      if (this.isEditMode) {
        this.updateObjects()
      } else {
        this.createObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    findProductPrice() {
      const product = this.products.find(p => p.id === this.productId)
      const productPrice = product.precios.find(pr => pr.distribuidor === this.institutionId)
      this.distributionPrice = productPrice.precio_distribuidor
      this.inventory.id_moneda = productPrice.moneda
    },
    fillData() {
      this.inventoryId = this.entity.id
      this.productId = this.entity.producto.id
      this.inventory = {
        id: this.inventoryId,
        id_opcion_producto: this.entity.opcion_producto.id,
        id_almacen: this.entity.almacen.id,
        cantidad_disponible: this.entity.cantidad_disponible,
        id_unidad_medida: this.entity.unidad_medida.id,
        comentarios: this.entity.comentarios,
        fecha_ultima_actualizacion: new Date(),
        id_autor: this.institutionId,
        id_institucion_educativa: this.entity.institucion_educativa.id
      }
      this.findProductPrice()
    }
  }
}
</script>

<style>

</style>